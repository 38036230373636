import {gql} from '@apollo/client';

export const UserFragment = gql`
	fragment UserFragmentFields on User {
		ID
		Username
		AvatarURL
		Entitlements {
			MarketIDs
			Markets {
				ID
				Name
			}
			Roles
			CMSRoles
			Web
			Healthcare
			Pharma {
				Standard
				Premium
			}
			MedTech {
				Standard
				Premium
			}
		}
		IsAnonymous
		IsInternal
		Email
		Name
		FirstName
		LastName
		Industry
		VerifiedAt
		CreatedOrigin
		ResearchAlertFrequency
		#NewsAlertFrequency
		RetrospectiveAlertFrequency
		UpcomingWebinarsAlertFrequency
		AccountOwner {
			Name
			ID
		}
		Account {
			Type
		}
		Timezone
		Title
		FV3WebTourViewedAt
		FollowedMarkets
		FollowedHubs
	}
`;
